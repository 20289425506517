











































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import GraphCard from "@/components/diagnosticsDetails/GraphCard.vue";
import BenchmarkCard from "@/components/diagnosticsDetails/BenchmarkCard.vue";
import StatisticsCard from "@/components/diagnosticsDetails/StatisticsCard.vue";
import LimitsCard from "@/components/diagnosticsDetails/LimitsCard.vue";
import ResultCard from "@/components/diagnosticsDetails/ResultCard.vue";
import { LongTrendPeriodMeta } from "@/types/longTrendPeriodMeta";
import { ILongTrendPeriod } from "@/types/longTrendPeriod";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import LongTrendPeriodModule from "@/store/clients/LongTrendPeriod.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import LongTrendExportClient from "@/Scripts/clients/long-trend-export-client";

const LongTrendPeriod = getModule(LongTrendPeriodModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    GraphCard,
    BenchmarkCard,
    StatisticsCard,
    LimitsCard,
    ResultCard,
  },
})
export default class DiagnosticsDetails extends Vue {
  dialog = false;
  loading = true;
  periodMeta!: LongTrendPeriodMeta;
  customBenchmarkPeriod = false;

  get meta(): LongTrendPeriodMeta | null {
    return LongTrendPeriod.meta;
  }
  get longTrendPeriod(): ILongTrendPeriod | null {
    return LongTrendPeriod.longTrendPeriod;
  }
  get title(): string | undefined {
    return this.meta?.description;
  }
  get hasNextPeriod(): boolean | undefined {
    return this.meta?.hasNextPeriod;
  }
  get hasPreviousPeriod(): boolean | undefined {
    return this.meta?.hasPreviousPeriod;
  }
  get nextPeriodStart(): string | undefined {
    return new Date(moment.utc(this.meta?.nextPeriodStartDate).valueOf()).toISOString();
  }
  get nextPeriodEnd(): string | undefined {
    return new Date(moment.utc(this.meta?.nextPeriodEndDate).valueOf()).toISOString();
  }
  get prevPeriodStart(): string | undefined {
    return new Date(moment.utc(this.meta?.previousPeriodStartDate).valueOf()).toISOString();
  }
  get prevPeriodEnd(): string | undefined {
    return new Date(moment.utc(this.meta?.previousPeriodEndDate).valueOf()).toISOString();
  }
  get benchmarkPeriod(): number | undefined {
    return this.longTrendPeriod?.benchmarkPeriod;
  }

  exportExcel(): void {
    LongTrendExportClient.exportExcelPeriod(this.meta?.vesselId, this.meta?.longTrendId, this.meta?.startDate, this.meta?.endDate);
  }

  nextPeriod(): void {
    if (this.nextPeriodStart && this.nextPeriodEnd) {
      this.periodMeta.startDate = this.nextPeriodStart;
      this.periodMeta.endDate = this.nextPeriodEnd;
    }
    this.updateData();
  }

  previousPeriod(): void {
    if (this.prevPeriodStart && this.prevPeriodEnd) {
      this.periodMeta.startDate = this.prevPeriodStart;
      this.periodMeta.endDate = this.prevPeriodEnd;
    }
    this.updateData();
  }

  open(data: LongTrendPeriodMeta): void {
    this.periodMeta = data;
    this.dialog = true;
    this.updateData();
  }

  async updateData(): Promise<void> {
    this.customBenchmarkPeriod = false;
    this.loading = true;
    try {
      await Promise.all([
        LongTrendPeriod.updateMeta(this.periodMeta),
        LongTrendPeriod.updateLongTrendPeriod(this.periodMeta),
        LongTrendPeriod.updateStatistics(this.periodMeta),
        LongTrendPeriod.updateTrendLimits(this.periodMeta.vesselId),
      ]);
    } catch (error) {
      this.close();
      Snackbar.showSnackbar({ text: "Could not load trend period details" });
    } finally {
      this.loading = false;
    }
  }

  async customPeriod(points: number): Promise<void> {
    const clonedMeta = Object.assign({}, this.periodMeta);
    const customPeriod = {
      customBenchmarkPeriod: points,
    };
    const data = Object.assign(clonedMeta, customPeriod);
    this.loading = true;
    await LongTrendPeriod.updateLongTrendPeriod(data);
    if (points !== -1) {
      this.customBenchmarkPeriod = true;
    } else {
      this.customBenchmarkPeriod = false;
    }
    this.loading = false;
  }

  close(): void {
    this.dialog = false;
    LongTrendPeriod.DELETE_DATA();
  }
}
