




















import { Component, Prop, Vue } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import store from "@/store";
import moment from "moment";
//  types
import { ILongTrendPeriod } from "@/types/longTrendPeriod";
import { LongTrendPeriodMeta } from "@/types/longTrendPeriodMeta";
import { GraphPoint } from "@/types/graphPoint";
import { TrendLine } from "@/types/trendLine";
//  modules
import { getModule } from "vuex-module-decorators";
import LongTrendPeriodModule from "@/store/clients/LongTrendPeriod.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";

const LongTrendPeriod = getModule(LongTrendPeriodModule, store);
const VesselEvents = getModule(VesselEventsModule, store);

//How it was made in old solution:
//KspOfficeWeb.WebApplication\Views\Diagnostics\DetailsGraph.cshtml
//sendt data here for final assembly:
//src\Scripts\misc\chart.js

//TODOS
//tooltip on vesselevents in chart: graph is overflow hidden, so added marginTop to fit.
//v-tooltip doesn't render, so i just made a simple css-only tooltip for now.
//Issue: clips at the ends but maybe not a big deal, if you want tooltip to always be fully visible,
//can use title attribute, but this can not be styled obviously

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class GraphCard extends Vue {
  @Prop({ default: true }) loading!: boolean;
  chart!: any;

  chartReady(chart: any): void {
    this.chart = chart;
    this.addEventLabels();
    const cmax = this.isRpm ? 25 : 50;
    const cmin = this.isRpm ? -25 : -50;
    this.chart.yAxis[0].setExtremes(cmin, cmax);
  }

  //just copying this from chart.js. Original comment:
  //Adds "Z" to datetime strings and, optionally, removes already existing
  //UTC/timezone difference suffix (like '+01:00')
  formatDatetimeToUTC(datetime: any): any {
    datetime = datetime.split("+");
    return datetime[0].endsWith("z") || datetime[0].endsWith("Z") ? datetime[0] : datetime[0] + "Z";
  }

  addEventLabels(): void {
    this.infoEvents.forEach(event => {
      const html = `
          <i class="plot-line-label-icon mdi ${this.eventIcon(event.type)}"></i>
          <div class="plot-line-tooltip">
            <p><b>${this.formatDateForDisplay(event.timestamp)}</b></p>
            <p>${event.name}</p>
          </div>
        `;
      this.chart.xAxis[0].addPlotLine({
        value: new Date(this.formatDatetimeToUTC(event.timestamp)).getTime(),
        color: "#0060fe",
        width: 2,
        id: "plot-line-" + event.id,
        label: {
          rotation: 0,
          style: {
            color: "#0060fe",
          },
          text: html,
          useHTML: true,
          y: -14,
          x: -11,
        },
      });
    });
  }

  get longTrendPeriod(): ILongTrendPeriod | null {
    return LongTrendPeriod.longTrendPeriod;
  }

  get meta(): LongTrendPeriodMeta | null {
    return LongTrendPeriod.meta;
  }

  //it looks like we don't need to filter these to the graph range,
  //as only the ones inside the range of the graph are shown anyway
  get infoEvents(): any[] {
    return VesselEvents.infoEvents;
  }

  get longTrendData(): GraphPoint[] | undefined {
    return this.longTrendPeriod?.longTrendData;
  }

  get isBenchmarking(): boolean | undefined {
    return this.longTrendPeriod?.isBenchmarking;
  }

  get isManualBenchmark(): boolean | undefined {
    return this.longTrendPeriod?.isManualBenchmark;
  }

  get benchmarkPoint(): GraphPoint | undefined {
    return this.longTrendPeriod?.benchmarkPoint;
  }

  get benchmarkingZone(): any {
    const benchmarkZone: any = {
      from: null,
      to: null,
    };
    if (!this.isBenchmarking && !this.isManualBenchmark) {
      benchmarkZone.from = new Date(this.formatDatetimeToUTC(this.longTrendPeriod?.longTrendData[0].x)).getTime();
      benchmarkZone.to = new Date(this.formatDatetimeToUTC(this.benchmarkPoint?.x)).getTime();
    }
    return benchmarkZone;
  }

  get isRpm(): boolean | undefined {
    return this.longTrendPeriod?.isRpmDiagnostic;
  }

  get redOffset(): number | undefined {
    return this.longTrendPeriod?.redOffset;
  }

  get yellowOffset(): number | undefined {
    return this.longTrendPeriod?.yellowOffset;
  }

  get yellowLine(): GraphPoint | undefined {
    return this.longTrendPeriod?.yellowLine;
  }

  get redLine(): GraphPoint | undefined {
    return this.longTrendPeriod?.redLine;
  }

  get startDate(): string | undefined {
    return this.longTrendPeriod?.start;
  }

  get endDate(): string | undefined {
    return this.longTrendPeriod?.end;
  }

  get trendline(): TrendLine | undefined {
    return this.longTrendPeriod?.trendline;
  }

  get trialPoints(): GraphPoint[] | undefined {
    return this.longTrendPeriod?.trialPoints;
  }

  get exportName(): string | undefined {
    if (this.meta) {
      return `${this.meta.vesselName} _ ${this.meta.description}`;
    }
    return;
  }

  get exportTitle(): string | undefined {
    if (this.meta) {
      return `${this.meta.vesselName} - ${this.meta.description}`;
    }
    return;
  }

  get chartOptions(): unknown {
    if (!this.longTrendPeriod || !this.meta) return {};

    return {
      time: {
        useUTC: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        spacingRight: 20,
        marginTop: 55, //to fit tooltip
        animation: false,
        spacingTop: 20,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      title: {
        text: null,
      },

      xAxis: {
        type: "datetime",
        gridLineWidth: 1,
        plotBands: [
          {
            id: "benchmarkPeriod",
            from: this.benchmarkingZone.from,
            to: this.benchmarkingZone.to,
            color: "rgba(0,0,255,0.2)",
            zIndex: 1,
            label: {
              text: "Benchmark period",
            },
          },
        ],
        plotLines: [
          {
            id: "benchmarkPeriodEnd",
            value: this.benchmarkingZone.to,
            color: "rgb(0,0,255)",
            zIndex: 2,
            width: 2,
          },
        ],
        events: {
          setExtremes: (e: any) => {
            var cmax = this.isRpm ? 25 : 50;
            var cmin = this.isRpm ? -25 : -50;

            setTimeout(() => {
              //the documented recommended way of determining if reset zoom was clicked does not work
              //(e.min and e.max are supposed to be undefined, but aren't, so we use
              //this other way where we check if zoom button exists or not)
              if (this.chart.resetZoomButton) {
                this.chart.yAxis[0].setExtremes();
              } else {
                this.chart.yAxis[0].update({ min: cmin, max: cmax });
              }
            }, 0);
          },
        },
        title: {
          text: null,
        },
        labels: {
          y: 35,
        },
      },
      yAxis: {
        title: {
          text: this.yAxisLabel,
        },
        gridLineColor: "#aaaaaa",
        tickPixelInterval: 20,
        plotBands: this.plotBands,
        plotLines: this.plotLines,
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<small>{point.key}</small><br>",
        pointFormat: "Value: <strong>{point.y}</strong>",
        valueDecimals: 1,
        valueSuffix: "%",
        xDateFormat: "%d. %b, %Y",
      },
      plotOptions: {
        series: {
          zIndex: 1,
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            lineWidth: 0,
            lineColor: "#000000",
            fillColor: "#e60000",
          },
        },
      },
      exporting: {
        enabled: false,
        filename: this.exportName,
        chartOptions: {
          title: {
            text: this.exportTitle,
            style: {
              width: "450px",
            },
          },
        },
      },
      series: this.series,
    };
  }

  get yAxisLabel(): string {
    return `${this.longTrendPeriod?.unitName} (${this.longTrendPeriod?.unitCaption})`;
  }

  get plotBands(): any {
    if (this.isBenchmarking) return [];

    const plotBands: any = [];
    if (this.redOffset && this.yellowOffset && this.redOffset < this.yellowOffset) {
      plotBands.push({
        name: "pb-green",
        color: "rgba(69,186,69,0.5)",
        from: this.yellowLine?.y,
        to: 100,
      });
      plotBands.push({
        id: "pb-yellow",
        color: "rgba(226,226,29,0.5)",
        from: this.redLine?.y,
        to: this.yellowLine?.y,
      });
      plotBands.push({
        name: "pb-red",
        color: "rgba(196,59,59,0.5)",
        from: -100,
        to: this.redLine?.y,
      });
    } else {
      // Red at the top, green at the bottom
      plotBands.push({
        name: "pb-red",
        color: "rgba(196,59,59,0.5)",
        from: this.redLine?.y,
        to: 100,
      });
      plotBands.push({
        id: "pb-yellow",
        color: "rgba(226,226,29,0.5)",
        from: this.yellowLine?.y,
        to: this.redLine?.y,
      });
      plotBands.push({
        name: "pb-green",
        color: "rgba(69,186,69,0.5)",
        from: -100,
        to: this.yellowLine?.y,
      });
    }
    return plotBands;
  }

  get plotLines(): any {
    if (this.isBenchmarking) return [];

    const plotLines: any = [];
    if (this.redOffset && this.yellowOffset && this.redOffset < this.yellowOffset) {
      plotLines.push({
        id: "red-yellow-line",
        value: this.redLine?.y,
        color: "#888888",
        zIndex: 1,
        width: 1,
      });
      plotLines.push({
        id: "yellow-green-line",
        value: this.yellowLine?.y,
        color: "#888888",
        zIndex: 1,
        width: 1,
      });
    } else {
      plotLines.push({
        id: "red-yellow-line",
        value: this.yellowLine?.y,
        color: "#888888",
        zIndex: 1,
        width: 1,
      });
      plotLines.push({
        id: "yellow-green-line",
        value: this.redLine?.y,
        color: "#888888",
        zIndex: 1,
        width: 1,
      });
    }
    return plotLines;
  }

  get series(): any {
    const series: any = [];
    if (!this.isBenchmarking) {
      series.push({
        name: "Baseline",
        type: "line",
        color: "#000000",
        lineWidth: 1,
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
        data: [
          [new Date(this.formatDatetimeToUTC(this.startDate)).getTime(), 0],
          [new Date(this.formatDatetimeToUTC(this.endDate)).getTime(), 0],
        ],
      });

      series.push({
        name: "Trendline",
        type: "line",
        lineWidth: 3,
        color: "#008000",
        zIndex: 3,
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
        data: [
          [new Date(this.formatDatetimeToUTC(this.trendline?.start.x)).getTime(), this.trendline?.start.y],
          [new Date(this.formatDatetimeToUTC(this.trendline?.end.x)).getTime(), this.trendline?.end.y],
        ],
      });

      series.push({
        name: "Benchmark",
        type: "line",
        dashStyle: "dash",
        color: "#0000FF",
        zIndex: 2,
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
        data: [
          [new Date(this.formatDatetimeToUTC(this.benchmarkPoint?.x)).getTime(), this.benchmarkPoint?.y],
          [new Date(this.formatDatetimeToUTC(this.endDate)).getTime(), this.benchmarkPoint?.y],
        ],
      });
    }

    const longTrendData = [];
    if (this.longTrendData) {
      for (var j = 0; j < this.longTrendData.length; j++) {
        longTrendData.push([new Date(this.formatDatetimeToUTC(this.longTrendData[j].x)).getTime(), this.longTrendData[j].y]);
      }
    }
    series.push({
      name: "Long trend",
      type: "scatter",
      enableMouseTracking: true,
      data: longTrendData,
    });

    if (this.trialPoints && this.trialPoints.length > 0) {
      const trialPoints = [];
      for (var j = 0; j < this.trialPoints.length; j++) {
        trialPoints.push([new Date(this.formatDatetimeToUTC(this.trialPoints[j].x)).getTime(), this.trialPoints[j].y]);
      }

      series.push({
        name: "Trials",
        type: "scatter",
        color: "#0000FF",
        enableMouseTracking: true,
        zIndex: 1,
        marker: {
          enabled: true,
          symbol: "diamond",
          radius: 3,
          fillColor: "#8989ff",
        },
        data: trialPoints,
      });
    }
    return series;
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  formatDateForDisplay(date: any): string {
    return moment(date).format("DD.MMM YYYY");
  }

  eventIcon(eventType: string): string {
    return eventType === "TrendEvent" ? "mdi-alpha-t-circle" : "mdi-alpha-i-circle";
  }
}
