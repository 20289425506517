import net from "App/net";

var LongTrendExportClient = function () {

};

LongTrendExportClient.prototype.exportExcel = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };
    return net.fileDownload("/LongTrend/Excel", "GET", filter);
};

LongTrendExportClient.prototype.exportExcelPeriod = function(vesselId, longTrendId, startDate, endDate) {
    var filter = {
        vesselId: vesselId,
        longTrendId: longTrendId,
        startDate: startDate,
        endDate: endDate,
    };

    return net.fileDownload("/LongTrend/Excel", "GET", filter);
};

export default new LongTrendExportClient();
