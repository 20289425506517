









































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
import { getModule } from "vuex-module-decorators";
import diagnosticTypesHelper from "Utilities/diagnostic-types-helper";
import { ITrendBenefit } from "@/types/trendBenefit";
import { ILongTrend } from "@/types/longTrend";
import BenefitsModule from "@/store/clients/Benefits.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
import { VesselEvent } from "@/types/vesselEvent";

const TrendEventBenefits = getModule(BenefitsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);

@Component({})
export default class TrendEventBenefit extends Vue {
  @Prop() readonly longTrend!: ILongTrend;

  benefitData: ITrendBenefit | null = null;
  isBenefitLoading = true;
  selectedTrendEvent: any = {};
  loadBenefitError: string | boolean = false;

  @Watch("trendEvents")
  trendEventsChanged(): void {
    this.selectedTrendEvent = this.trendEvents[0];
  }

  @Watch("longTrend")
  longtrendchanged(): void {
    this.fetchBenefitData();
  }

  async created(): Promise<void> {
    this.selectedTrendEvent = this.trendEvents[0];
    await this.fetchBenefitData();
  }

  async fetchBenefitData(): Promise<void> {
    try {
      this.isBenefitLoading = true;
      this.benefitData = await TrendEventBenefits.fetchBenefit({ trendEventId: this.selectedTrendEvent.id, longTrendId: this.longTrend.id });
      this.loadBenefitError = false;
    } catch (error) {
      this.loadBenefitError = error as string;
    } finally {
      this.isBenefitLoading = false;
    }
  }

  get trendEvents(): VesselEvent[] {
    return VesselEvents.trendEvents;
  }

  get benefitText(): string {
    return diagnosticTypesHelper.getBenefitText(this.longTrend.diagnosticType);
  }

  get hasConsequenceResults(): boolean {
    return this.benefitData?.consequences?.length ? this.benefitData.consequences[0].results.length > 0 : false;
  }

  get tableHeaders(): any[] {
    const headers: any[] = [];

    if (!this.benefitData?.consequences[0].results.length) return headers;

    headers.push({ text: "MCR", value: "MCR" });

    this.benefitData?.consequences[0].results.forEach(result => {
      headers.push({ text: result.headerName, value: result.headerName });
    });

    return headers;
  }

  get tableItems(): any[] {
    const items: any[] = [];

    this.benefitData?.consequences.forEach(consequence => {
      const item: any = { MCR: consequence.mcrDisplay };

      consequence.results.forEach(result => {
        item[result.headerName] = result.displayValue;
      });
      items.push(item);
    });

    return items;
  }

  get errorText(): string {
    switch (this.loadBenefitError) {
      case "Benchmarking":
        return "Could not calculate benefit. This might be because the trend period before or after selected trend event is benchmarking, or have no data.";
      default:
        return "Could not calculate benefit.";
    }
  }

  formatDateForDisplay(date: any): string {
    return moment(date).format("DD.MMM YYYY");
  } //use parent?
}
