













































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import LongTrendPeriodModule from "@/store/clients/LongTrendPeriod.module";
import { LongTrendPeriodStatistics } from "@/types/longTrendPeriodStatistics";
import { ILongTrendPeriod } from "@/types/longTrendPeriod";

const LongTrendPeriod = getModule(LongTrendPeriodModule, store);

@Component
export default class StatisticsCard extends Vue {
  @Prop({ default: true }) loading!: boolean;

  tooltip = "The number of long trend points shows the number of days where the conditions were suitable for a data point to be included.";

  get statistics(): LongTrendPeriodStatistics | null {
    return LongTrendPeriod.statistics;
  }
  get longTrendPeriods(): ILongTrendPeriod | null {
    return LongTrendPeriod.longTrendPeriod;
  }
  get pointCount(): number | undefined {
    return this.longTrendPeriods?.longTrendData?.length;
  }
  get isBenchmarking(): boolean | undefined {
    return this.longTrendPeriods?.isBenchmarking;
  }
  get averageMean(): number | undefined {
    return this.statistics?.averageMean;
  }
  get correlation(): number | undefined {
    return this.statistics?.correlation;
  }
  get diffPerMonth(): number | undefined {
    return this.statistics?.diffPerMonth;
  }
  get diffPerYear(): number | undefined {
    return this.statistics?.diffPerYear;
  }
  get standardDeviation(): number | undefined {
    return this.statistics?.standardDeviation;
  }
  get trendFunction(): string | undefined {
    return this.statistics?.trendFunction;
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
