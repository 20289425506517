







































































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import LongTrendPeriodModule from "@/store/clients/LongTrendPeriod.module";
import { TrendLimit } from "@/types/trendLimit";

const LongTrendPeriod = getModule(LongTrendPeriodModule, store);

@Component
export default class LimitsCard extends Vue {
  @Prop({ default: true }) loading!: boolean;

  tooltip = "The on board data collection limits met for valid long trend points";

  get limits(): TrendLimit | null {
    return LongTrendPeriod.trendLimits;
  }

  round(value: number, precision = 1): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
