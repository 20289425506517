//Not complete. More options can be added from Highcharts API as needed.
export interface IPlotLine {
    value: number,
    color: string,
    width: number,
    zIndex?: number,
    id: string,
    label: {
        rotation: number,
        style: {
            color: string,
        },
        text: string,
        useHTML: boolean,
        y: number,
        x: number,
    },
}

export const DefaultVesselEventPlotline: IPlotLine = {
    value: 0,
    color: "#000000",
    width: 2,
    id: "plotline",
    zIndex: 5,
    label: {
        rotation: 0,
        style: {
            color: "#000000",
        },
        text: "",
        useHTML: true,
        y: -10,
        x: -10,
    },
}
