
















































































































































































import store from "@/store";
import moment from "moment";
import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import DiagnosticTypesHelper from "Utilities/diagnostic-types-helper";
//modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
import LongTrendsModule from "@/store/clients/LongTrends.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
//types
import { ILongTrend } from "@/types/longTrend";
import { Vessel } from "@/types/Vessel";
import { ILongTrendPeriod } from "@/types/longTrendPeriod";
import { VesselEvent } from "@/types/vesselEvent";
import { IPlotLine, DefaultVesselEventPlotline } from "@/types/highcharts/plotLine";
import { IPlotBand } from "@/types/highcharts/plotBand";
import { IDataSerie } from "@/types/highcharts/dataSerie";
//components
import GraphCard from "@/components/diagnosticsDetails/GraphCard.vue";
import { Chart } from "highcharts-vue";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import Highcharts from "highcharts";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
import TrendEventBenefit from "@/components/diagnostics/TrendEventBenefit.vue";
import { LongTrendPeriodMeta } from "@/types/longTrendPeriodMeta";

HighchartsNoData(Highcharts);

const Vessels = getModule(VesselsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);
const LongTrends = getModule(LongTrendsModule, store);
const Snackbar = getModule(SnackbarModule, store);

interface StatusIcon {
  name: string;
  color: string;
}

@Component({
  components: {
    LoadingTextWave,
    GraphCard,
    Highcharts: Chart,
    TrendEventBenefit,
  },
})
export default class DiagnosticCard extends Vue {
  @Prop() readonly longTrendName!: string;
  @Prop() readonly visible!: boolean;
  @Ref() readonly chart!: any;

  showError = false;

  useManualBenchmarkLevel = false;
  manualBenchmarkLevel = 0;

  isLongTrendsLoading = true;
  isBenchmarkLevelSettingsLoading = false;

  async created(): Promise<void> {
    if (!this.vessel || !this.longTrend) {
      this.showError = true;
      this.isLongTrendsLoading = false;
    } else {
      try {
        await Promise.all([LongTrends.fetchVesselLongTrendPeriods({ vesselId: this.vessel.id, longTrend: this.longTrend })]);
        this.manualBenchmarkLevel = this.longTrend.manualBenchmarkLevel;
        this.useManualBenchmarkLevel = this.longTrend.useManualBenchmarkLevel;
      } catch (error) {
        this.showError = true;
        console.warn(error);
      } finally {
        this.isLongTrendsLoading = false;
      }
    }
  }

  @Watch("vesselEvents")
  onVesselEventsChanged(): void {
    if (!this.vessel) return;

    LongTrends.fetchVesselLongTrendPeriods({ vesselId: this.vessel.id, longTrend: this.longTrend });
  }

  get longTrend(): ILongTrend {
    return LongTrends.vesselLongTrendByName(this.longTrendName).longTrend;
  }

  get longTrendPeriods(): ILongTrendPeriod[] {
    return this.longTrend ? LongTrends.vesselLongTrendByName(this.longTrendName).longTrendPeriods : [];
  }

  get vesselEvents(): VesselEvent[] {
    return VesselEvents.allEvents;
  }

  get firstLongTrendPeriod(): ILongTrendPeriod {
    return this.longTrendPeriods[0];
  }

  get tooltipText(): string {
    return DiagnosticTypesHelper.getHelpText(this.longTrend.diagnosticType);
  }

  get noTrendEvents(): any {
    return !VesselEvents.trendEvents?.length;
  }

  get vessel(): Vessel | null {
    return Vessels.currentVessel;
  }

  get statusIcon(): StatusIcon {
    switch (this.longTrend.performanceStatus) {
      case "Ok":
        return { name: "mdi-check-circle", color: "success" };
      case "NotOk":
        return { name: "mdi-alert-circle", color: "error" };
      case "Observe":
        return { name: "mdi-alert-circle", color: "warning" };
      case "BenchMarking":
        return { name: "mdi-gauge", color: "info darken-4" };
      default:
        return { name: "mdi-minus-circle", color: "grey" };
    }
  }

  get benchmarkType(): { tooltip: string; icon: string } {
    if (this.longTrend.useManualBenchmarkLevel) {
      return {
        tooltip: "Using manual benchmark level: " + this.longTrend.manualBenchmarkLevel + "%",
        icon: "mdi-alpha-m-circle",
      };
    } else {
      return {
        tooltip: "Using automatic calculated benchmark level",
        icon: "mdi-alpha-a-circle",
      };
    }
  }

  getVesselEventsOnSameDate(eventTimestamp: string): VesselEvent[] {
    return this.vesselEvents.filter((vesselEvent: VesselEvent) => vesselEvent.timestamp === eventTimestamp);
  }

  getPlotLineLabels(vesselEventsOnSameDate: VesselEvent[], event: VesselEvent): string {
    return `
                <span class="plot-line-label-icon mdi ${this.eventIcon(event.type)}">
                  <i
                    class="multiple-event-counter"
                    style="display: ${vesselEventsOnSameDate.length >= 2 ? "block" : "none"}"
                  >
                    ${vesselEventsOnSameDate.length}
                  </i>
                </span>
                <div class="plot-line-tooltip">
                  ${vesselEventsOnSameDate
                    .map(
                      (vesselEvent: VesselEvent, index: number) => `
                    <p>
                      <i
                        class="plot-line-label-icon mdi ${this.eventIcon(vesselEvent.type)} mr-2"
                        style="display: ${vesselEventsOnSameDate.length >= 2 ? "block" : "none"}"
                      ></i>
                      <b>${this.formatDateForDisplay(vesselEvent.timestamp)}</b>
                    </p>
                    <p>${vesselEvent.name}</p>
                    <hr class="my-2" style="display: ${vesselEventsOnSameDate.length - 1 === index ? "none" : "block"}" />
                  `
                    )
                    .join("")}
                </div>
              `;
  }

  get vesselEventPlotLines(): IPlotLine[] {
    const plotLines: IPlotLine[] = [];

    this.vesselEvents.forEach(event => {
      const html = this.getPlotLineLabels(this.getVesselEventsOnSameDate(event.timestamp), event);
      plotLines.push(
        Object.assign(JSON.parse(JSON.stringify(DefaultVesselEventPlotline)), {
          id: `plotline-${event.id}`,
          color: this.eventColor(event.type),
          value: moment.utc(event.timestamp).valueOf(),
          width: 2,
          label: {
            rotation: 0,
            style: { color: this.eventColor(event.type) },
            text: html,
            useHTML: true,
            y: -15,
            x: -11,
          },
        })
      );
    });

    return plotLines;
  }

  get vesselEventPlotBands(): IPlotBand[] {
    const plotBands: IPlotBand[] = [];

    this.longTrendPeriods.forEach(period => {
      plotBands.push({
        from: moment.utc(period.start).valueOf(),
        to: moment.utc(period.end).valueOf(),
        id: period.longTrendId,
        className: "diagnotic-graph__plot-bands",
        color: "rgba(0,0,0,0.0)",
        zIndex: 4,
        events: {
          click: () => {
            this.$emit("plotBandClicked", this.getLongTrendPeriodMeta(period));
          },
          mouseover: (e: any) => {
            e.target.attributes.getNamedItem("fill").value = "#ccd9cc4D";
          },
          mouseout: (e: any) => {
            e.target.attributes.getNamedItem("fill").value = "rgba(0,0,0,0.0)";
          },
        },
      });
    });

    return plotBands;
  }

  get dataSeries(): IDataSerie[] {
    if (!this.longTrend) {
      this.showError = true;
      return [];
    } else {
      const series: IDataSerie[] = [];
      const longTrendData: [number, number][] = [];
      const benchmarkingData: any[] = [];

      this.longTrendPeriods.forEach(period => {
        //graph points for long trend data
        period.longTrendData.forEach(dataPoint => {
          longTrendData.push([new Date(this.formatDatetimeToUTC(dataPoint.x)).getTime(), dataPoint.y]);
        });

        //graph points for benchmark data
        if (!this.longTrend.useManualBenchmarkLevel && period.benchmarkPoint) {
          benchmarkingData.push(
            [new Date(this.formatDatetimeToUTC(period.benchmarkPoint.x)).getTime(), period.benchmarkPoint.y],
            [new Date(this.formatDatetimeToUTC(period.end)).getTime(), period.benchmarkPoint.y]
          );
        }

        if (period.trendline) {
          series.push({
            name: `Trendline (${this.formatDateForDisplay(period.trendline.start.x)})`,
            type: "line",
            color: "#008000",
            zIndex: 2,
            marker: {
              enabled: false,
            },
            tooltip: {
              valueDecimals: 1,
              valueSuffix: "%",
            },
            data: [
              [new Date(this.formatDatetimeToUTC(period.trendline.start.x)).getTime(), period.trendline.start.y],
              [new Date(this.formatDatetimeToUTC(period.trendline.end.x)).getTime(), period.trendline.end.y],
            ],
          });
        }
      });

      series.push({
        name: "Deviation from baseline",
        type: "line",
        color: "#800000",
        zIndex: 1,
        enableMouseTracking: true,
        marker: {
          symbol: "circle",
        },
        tooltip: {
          valueDecimals: 1,
          valueSuffix: "%",
        },
        data: longTrendData,
      });

      series.push({
        name: "Benchmark",
        type: "line",
        dashStyle: "dash",
        color: "#0000FF",
        zIndex: 4,
        visible: false,
        tooltip: {
          valueDecimals: 1,
          valueSuffix: "%",
        },
        data: this.longTrend.useManualBenchmarkLevel
          ? [
              [new Date(this.formatDatetimeToUTC(this.firstLongTrendPeriod.start)).getTime(), this.longTrend.manualBenchmarkLevel],
              [new Date().getTime(), this.longTrend.manualBenchmarkLevel],
            ]
          : benchmarkingData,
      });

      series.push({
        name: "Baseline",
        type: "line",
        color: "#000000",
        lineWidth: 1,
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
        data: [
          [new Date(this.formatDatetimeToUTC(this.firstLongTrendPeriod.start)).getTime(), 0],
          [new Date().getTime(), 0],
        ],
      });

      return series;
    }
  }

  get chartOptions(): any {
    if (!this.longTrendPeriods?.length) return {};

    const options = {
      time: {
        useUTC: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        spacingRight: 20,
        animation: false,
        height: 300,
        spacingTop: 40,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      title: {
        text: null,
      },
      yAxis: {
        title: {
          text: `${this.firstLongTrendPeriod.unitName} (${this.firstLongTrendPeriod.unitCaption})`,
        },
        min: this.firstLongTrendPeriod.isRpmDiagnostic ? -25 : -50,
        max: this.firstLongTrendPeriod.isRpmDiagnostic ? 25 : 50,
        tickPixelInterval: 20,
      },
      xAxis: {
        title: {
          text: null,
        },
        type: "datetime",
        plotLines: this.vesselEventPlotLines,
        plotBands: this.vesselEventPlotBands,
        labels: {
          y: 35,
        },
      },
      series: this.dataSeries,
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, .85)",
        borderWidth: 2,
        style: {
          color: "#EBEBEB",
        },
      },
      plotOptions: {
        series: {
          zIndex: 1,
        },
      },
      exporting: {
        filename: `${this.vessel?.name}_${this.longTrend.descriptionLong}`,
        chartOptions: {
          legend: {
            enabled: false,
          },
          title: {
            text: `${this.vessel?.name} [${this.longTrend.descriptionLong}]`,
            style: {
              width: "450px",
            },
          },
        },
      },
    };

    return options;
  }

  get disableSaveBenchmarkLevelSettings(): boolean {
    return (
      (!this.longTrend.useManualBenchmarkLevel === !this.useManualBenchmarkLevel && this.longTrend.manualBenchmarkLevel.toString() === this.manualBenchmarkLevel.toString()) ||
      this.manualBenchmarkLevel > 40 ||
      this.manualBenchmarkLevel < -40
    );
  }

  benchmarkLevelInputRules(value: any): boolean | string {
    if (!!!value) return "Required";
    else return (-40 <= value && value <= 40) || "Benchmark level must be a number between -40 and 40.";
  }

  resetManualBenchmarkLevel(): void {
    if (!!!this.manualBenchmarkLevel) {
      this.manualBenchmarkLevel = this.longTrend ? this.longTrend.manualBenchmarkLevel : 0;
    }
  }

  async updateBenchmarkLevelSettings(): Promise<void> {
    this.isBenchmarkLevelSettingsLoading = true;
    const updatedLongTrend: ILongTrend = JSON.parse(JSON.stringify(this.longTrend));
    updatedLongTrend.useManualBenchmarkLevel = this.useManualBenchmarkLevel;
    updatedLongTrend.manualBenchmarkLevel = this.manualBenchmarkLevel;

    try {
      await LongTrends.updateVesselLongTrend({ vesselId: this.vessel!.id, longTrend: updatedLongTrend });
      await Vessels.updateCurrentVesselById(this.vessel!.id);
      Snackbar.showSnackbar({ text: "Benchmark settings successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update benchmark settings" });
    } finally {
      this.isBenchmarkLevelSettingsLoading = false;
    }
  }

  formatDatetimeToUTC(datetime: any): any {
    datetime = datetime.split("+");
    return datetime[0].endsWith("z") || datetime[0].endsWith("Z") ? datetime[0] : datetime[0] + "Z";
  }

  formatDateForDisplay(date: any): string {
    return moment.utc(date).format("DD.MMM YYYY");
  }

  getLongTrendPeriodMeta(longTrendPeriod: ILongTrendPeriod): Partial<LongTrendPeriodMeta> {
    return {
      longTrendId: longTrendPeriod.longTrendId,
      vesselId: longTrendPeriod.vesselId,
      startDate: moment.utc(longTrendPeriod.start).toISOString(),
      endDate: moment.utc(longTrendPeriod.end).toISOString(),
    };
  }

  eventIcon(eventType: string): string {
    return eventType === "TrendEvent" ? "mdi-alpha-t-circle" : "mdi-alpha-i-circle";
  }

  eventColor(eventType: string): string {
    return eventType === "InfoEvent" ? "#0060fe" : "#008000";
  }
}
